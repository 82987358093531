

:root {
  --main-accent-color: #ff6666;
  --gutter-width: 1rem;
  --outer-margin: 2rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
  --xs-min: 30;
  --sm-min: 48;
  --md-min: 64;
  --lg-min: 75;
  --screen-xs-min: var(--xs-min)em;
  --screen-sm-min: var(--sm-min)em;
  --screen-md-min: var(--md-min)em;
  --screen-lg-min: var(--lg-min)em;
  --container-sm: calc(var(--sm-min) + var(--gutter-width));
  --container-md: calc(var(--md-min) + var(--gutter-width));
  --container-lg: calc(var(--lg-min) + var(--gutter-width));
}

@custom-media --sm-viewport only screen and (min-width: 48em);
@custom-media --md-viewport only screen and (min-width: 64em);
@custom-media --lg-viewport only screen and (min-width: 75em);

.container-fluid, .container {
  margin-right: auto;
  margin-left: auto;
}


html, body, #app, #app>div {
  height: 100%;
}


#root {
    height: 100%;
}

.fullheight {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Armata', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {

}

a:link {
  color: white;
}

a:link:hover {
  color: grey;
}

a.underline {
  text-decoration: underline;
}

div {
  justify-content: center;
}

ul {
  list-style-type: none;
  display: flex;
}

.content-left {
  justify-content: left;
}

.blue-box {
  background-color: white;
  height: 800px;
}

.page-container{
  display: flex;
  height: 100vh;
}

.content-container {
  flex: 1 1 auto;
  overflow: auto;
  height: 100vh;
}

.sidebar {
  flex: 1 1 auto;
  background-color: var(--main-accent-color);
  overflow: auto;
}

img.full-width {
  width: 100%;
}

img.bock {
  width: 60%;
  align-self: left;
  align-items: flex-start;
}

.Intro {
  padding: 5% 0% 0% 0%;
  height: auto;
  background-color: white;
}

.sideline {
  justify-content: center;
  align-items: center;
}

.pink-line {
  background: var(--main-accent-color);
  height: 100%;
  width: 6%;
  animation-name: revealfromtop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-play-state: running;
  animation-delay: 0s;
}

@keyframes revealfromtop {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

.introText {
  font-size: 36px;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 10%;
}

.Categories {
  width: 100%;
}

.CategoryTab {
  display: flex;
  margin: 0%;
  width: 100%;
}

.category-tabs {
  justify-content: center;
}

.category-tab {
  margin: 5%;

}

#tab-parent {
  width: 100%;
}

.tab-title {
  opacity: 1;
  transition: 0s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

#tab-hover-content {
  opacity: 0;
  transition: 1s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.nav-item:hover #tab-hover-content {
  opacity: 1;
  color: var(--main-accent-color);
}

.nav-item:hover .tab-title {
  opacity: 0;
  transition: 0s;
}

.nav-item:hover .nav-link {
  border-color: var(--main-accent-color);
}

.nav {
  flex-direction: row;
  justify-content: center;
}

.nav-item {
  flex-direction: row;
  width: 100%;
  height: 200px;

}

.nav-pills .nav-link {
  color: grey !important;
  width: 100%;
  height: 200px;
  color: grey !important ;
  border-radius: 2px;
  border: solid;
  border-color: grey;
}

.nav-pills .nav-link.active {
  border-color: black;
  background-color: white !important;
  color: black !important;
}

.nav-pills .nav-link.active:hover {
  border-color: var(--main-accent-color);
  background-color: white !important;
  color: black !important;
}


.productionCategory {
  padding: 5%;
  flex-direction: row;
  justify-content: flex-start;
}

.Sidebar {
  background-color: var(--main-accent-color);
  color: white;
  padding: 5%;
}

.profile {
  margin: 10%;
}



.bm-burger-button {
  position: absolute;
  float: right;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}


.bm-burger-bars {
  background: var(--main-accent-color);

}

.bm-menu-wrap {
  width: 50% !important;
}

.bm-overlay {
  width: 50% !important;
  right: 0px;
}

@media only screen and (max-width: 48em) {


  .hide-small {
    display: none !important;
  }

}

@media only screen and (max-width: 64em) {

  .hide-medium {
    display: none !important;
  }

}



@media only screen and (min-width: 64em) {

  .hide-large {
    display: none !important;
  }

}

@media only screen and (min-width: 75em) {
  .hide-large {
    display: none !important;
  }

}
